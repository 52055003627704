import React from 'react'
import styled from 'styled-components'
import PetTheOzzy from '../../assets/pettheozzy.gif'

const Image = styled.img`
  display: block;
  margin: 50px auto 0;
`

const MainPageView = () => {

  return(
    <>
      <Image src={PetTheOzzy} alt={'Ozzy'} />
    </>
  )
}

export default MainPageView
