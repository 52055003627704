import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import {listenCollection} from '../../firebase/firestore/firestore'

const Container = styled.div`
  background-color: #21242e;
  width: 100vw;
  height: 100vh;
`

const Title = styled.h1`
  color: #fff;
  font-size: 1.2rem;
  margin-left: 10px;
`

const List = styled.ol`
  
`

const Team = styled.li`
  margin-bottom: 4px;

  a {
    color: #68D472;
    text-decoration: underline;
    font-weight: bold;
  }
  
  a:hover {
    color: #fff;
  }
`

const PlayerWithTracker = ({ player, tracker }) => (
  tracker
  ?
    <a href={tracker} target={'_blank'} rel='noreferrer'>{player}</a>
    :
    <>{player}</>
)

const TeamsWidget = () => {
  const [data, setData] = useState(null)

  useEffect(() => {
    return listenCollection('teams', d => setData(d))
  }, [])

  if(!data) return <i>Ładowanie widoku...</i>
  if(data.length === 0) return <i>Lista zawodników jest pusta</i>

  return(
    <Container>
      <Title>Lista uczestników:</Title>
      <List>
        {
          data.map(t => (
            <Team key={t.docId}>
              <PlayerWithTracker player={t.player1} tracker={t.tracker1} />
              {', '}
              <PlayerWithTracker player={t.player2} tracker={t.tracker2} />
              {' - '}
              {t.teamName}
            </Team>
          ))
        }
      </List>
    </Container>
  )
}

export default TeamsWidget
