import React from 'react'
import FirestoreTableBridge from '../../components/FirestoreTableBridge/FirestoreTableBridge'
import { message, Tooltip } from 'antd'
import { addNewTeam, updateTeam } from '../../firebase/firestore/entriesAndTeams.firestore'
import entryFormFields from '../../config/entryFormFields'
import copy from 'copy-to-clipboard'

const PlayerWithTracker = ({v, tracker}) => (
  v
    ?
    <>
      {v}<br />
      {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
      <a onClick={() => { copy(v); message.info('Skopiowano do schowka') }}>
        Skopiuj nick
      </a>
      <br />
      <Tooltip placement={'bottom'} title={tracker}>
        <a href={tracker} target={'_blank'} rel="noreferrer">Tracker</a>
      </Tooltip>
    </>
    :
    <i>(brak)</i>
)

const columns = [
  {
    title: 'Gracz nr 1',
    dataIndex: 'player1',
    render: (v, record) => <PlayerWithTracker v={v} tracker={record.tracker1} />,
  },
  {
    title: 'Gracz nr 2',
    dataIndex: 'player2',
    render: (v, record) => <PlayerWithTracker v={v} tracker={record.tracker2} />,
  },
  {
    title: 'Rezerwowy',
    dataIndex: 'reservePlayer',
    render: (v, record) => <PlayerWithTracker v={v} tracker={record.reserveTracker} />,
  },
  {
    title: 'Nazwa drużyny',
    dataIndex: 'teamName',
    // eslint-disable-next-line jsx-a11y/anchor-is-valid
    render: v => <>{v}<br />(<a onClick={() => { copy(v); message.info('Skopiowano do schowka') }}>Skopiuj nazwę</a>)</>,
  },
  {
    title: 'Najwyższa ranga',
    dataIndex: 'highestRank',
    render: v => v,
  },
]

const tableConfig = {
  sortOption: [
    {
      key: 'teamName',
      name: 'Nazwa drużyny',
      ascDirection: true,
      descDirection: true,
      extraFields: [],
    },
  ],
  newItemButton: true,
  actions: {
    editItemButton: true,
    deleteItemButton: true,
  },
  onNewItemFormSubmit: (data, accept) => {
    addNewTeam(data)
      .then(() => {
        accept()
        return message.success('Dodano nowy zapis')
      })
      .catch(() => message.error('Błąd aktualizacji!'))
  },
  onEditItemFormSubmit: (data, key, accept) => {
    updateTeam(key, data)
      .then(() => {
        accept()
        return message.success('Zapis został zaktualizowany')
      })
      .catch(() => message.error('Błąd aktualizacji!'))
  },
  formFields: entryFormFields,
}

const DashboardTeamsView = () => (
  <>
    <FirestoreTableBridge
      dataSourcePath={`teams`}
      defaultSortMethod={{ key: 'creationDate', direction: 'asc' }}
      columns={columns}
      tableConfig={tableConfig}
      itemsPerPage={false}
    />
  </>
)

export default DashboardTeamsView
