import React from 'react'
import styled from 'styled-components'
import KycuLiveLogo from '../assets/kycu-live-logo.png'

const Background = styled.div`
  background-color: #444444;
  min-height: 100vh;
`

const LogoContainer = styled.div`
  text-align: center;
  margin: 20px auto;
  
  img {
    max-width: 200px;
  }
`

const Content = styled.div`
  background-color: #ececec;
  max-width: 1300px;
  margin: 0 auto;
  min-height: 100vh;
  padding: 15px 25px;
`

const SimplePageLayout = ({ children }) => (
  <Background>
    <Content>
      <LogoContainer>
        <img src={KycuLiveLogo} alt={'kycu live logo'} />
      </LogoContainer>
      {children}
    </Content>
  </Background>
)

export default SimplePageLayout
