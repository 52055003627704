import React from 'react'
import styled from 'styled-components'
import { Button, Card } from 'antd'
import { Link } from 'react-router-dom'

const list = [
  { path: '/w/match', name: 'Aktualny mecz', description: 'Nakładka na transmisję meczu wyświetlająca nazwę obu drużym oraz ilość wygranych meczy w spotkaniu, jeśli został wybrany tryb "best of" większy niż 1.' },
  { path: '/w/schedule?mode=today', name: 'Dzisiejszy plan', description: 'Lista pokazująca tylko dzisiejsze zaplanowane w systemie mecze - pozostałe dni rozgrywek turniejowych są ukryte.' },
  { path: '/w/schedule', name: 'Przyszły plan', description: 'Lista pokazująca przyszłe zaplanowane w systemie mecze - od aktualnego dnia do końca całego turnieju.' },
  { path: '/w/schedule?mode=full', name: 'Pełny plan', description: 'Lista pokazująca wszystkie zarejestrowane w systemie mecze - od początku do końca całego turnieju.' },
  { path: '/w/teams', name: 'Lista uczestników', description: 'Lista wszystkich uczestników biorących udział w turnieju wraz z linkami do profili TRN.' },
]

const Container = styled.div`
  max-width: 800px;
`

const Widget = styled(Card)`
  margin-bottom: 20px;

  .ant-card-actions > li {
    text-align: right;
    padding-right: 12px;
  }
`

const DashboardWidgetsView = () => (
  <Container>
    {
      list.map(({ path, name, description }) => (
        <Widget
          key={path}
          title={name}
          actions={[
            <Link key={'open'} to={path} target='_blank' rel='noopener noreferrer'>
              <Button type={'primary'}>
                Pokaż widget
              </Button>
            </Link>
          ]}
        >
          {description}
        </Widget>
      ))
    }
  </Container>
)

export default DashboardWidgetsView
