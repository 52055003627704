import React from 'react'
import FirestoreTableBridge from '../../components/FirestoreTableBridge/FirestoreTableBridge'
import {timestampToString} from '../../utils/dateConverter'
import { message, Popconfirm, Button, Tooltip } from 'antd'
import {acceptEntry, addNewEntry, updateEntry} from '../../firebase/firestore/entriesAndTeams.firestore'
import {UsergroupAddOutlined} from '@ant-design/icons'
import entryFormFields from '../../config/entryFormFields'

const PlayerWithTracker = ({v, tracker}) => (
  v
    ?
    <>
      {v}<br />
      <Tooltip placement={'bottom'} title={tracker}>
        <a href={tracker} target={'_blank'} rel="noreferrer">Tracker</a>
      </Tooltip>
    </>
    :
    <i>(brak)</i>
)

const columns = [
  {
    title: 'Data utworzenia',
    dataIndex: 'creationDate',
    render: v => timestampToString(v),
  },
  {
    title: 'Gracz nr 1',
    dataIndex: 'player1',
    render: (v, record) => <PlayerWithTracker v={v} tracker={record.tracker1} />,
  },
  {
    title: 'Gracz nr 2',
    dataIndex: 'player2',
    render: (v, record) => <PlayerWithTracker v={v} tracker={record.tracker2} />,
  },
  {
    title: 'Rezerwowy',
    dataIndex: 'reservePlayer',
    render: (v, record) => <PlayerWithTracker v={v} tracker={record.reserveTracker} />,
  },
  {
    title: 'Nazwa drużyny',
    dataIndex: 'teamName',
    render: v => v,
  },
  {
    title: 'Najwyższa ranga',
    dataIndex: 'highestRank',
    render: v => v,
  },
]

const tableConfig = {
  sortOption: [
    {
      key: 'teamName',
      name: 'Nazwa drużyny',
      ascDirection: true,
      descDirection: true,
      extraFields: [],
    },
  ],
  newItemButton: true,
  actions: {
    editItemButton: true,
    deleteItemButton: true,
    custom: (idx, recordData, deleteItem) => ([
      <Popconfirm
        key={'accept'}
        title={'Czy chcesz zaakceptować drużynę?'}
        onConfirm={() => {
          acceptEntry(recordData.key, recordData)
            .then(() => {
              deleteItem(idx)
              return message.success('Drużyna została zaakceptowana!')
            })
        }}
        okText={'Dodaj do turnieju'}
        cancelText={'Anuluj'}
      >
        <Button size={'large'} icon={<UsergroupAddOutlined />} type={'primary'}>
          Zaakceptuj
        </Button>
      </Popconfirm>,
    ]),
  },
  onNewItemFormSubmit: (data, accept) => {
    addNewEntry(data)
      .then(() => {
        accept()
        return message.success('Dodano nowy zapis')
      })
      .catch(() => message.error('Błąd aktualizacji!'))
  },
  onEditItemFormSubmit: (data, key, accept) => {
    updateEntry(key, data)
      .then(() => {
        accept()
        return message.success('Zapis został zaktualizowany')
      })
      .catch(() => message.error('Błąd aktualizacji!'))
  },
  formFields: entryFormFields,
}

const DashboardEntriesView = () => (
  <>
    <FirestoreTableBridge
      dataSourcePath={`entries`}
      defaultSortMethod={{ key: 'creationDate', direction: 'asc' }}
      columns={columns}
      tableConfig={tableConfig}
      itemsPerPage={false}
    />
  </>
)

export default DashboardEntriesView
