import React, { useState, useEffect, useCallback } from 'react'
import styled from 'styled-components'
import {Form, Button, message} from 'antd'
import entryFormFields from '../../config/entryFormFields'
import firebase from '../../firebase/firebase'
import {addNewEntry} from '../../firebase/firestore/entriesAndTeams.firestore'
import FormCaptcha from './FormCaptcha'
import {UserOutlined} from '@ant-design/icons'

const formStatus = {
  loading: 'LOADING',
  enabled: 'ENABLED',
  disabled: 'DISABLED',
  sending: 'SENDING',
  sended: 'SENDED'
}

const FormContainer = styled.div`
  max-width: 560px;
  margin: 20px auto;
`

const Title = styled.h1`
  text-align: center;
  margin: 0 auto;
  font-size: 2.2rem;
`

const SubTitle = styled.h2`
  text-align: center;
  font-size: 1.2rem;
  margin-bottom: 40px;
`

const Message = styled.div`
  text-align: center;
  font-size: 1.1rem;
`

const SubInformation = styled.div`
  margin: 10px 0 15px;
  font-size: 0.8rem;
`

const TRNbutton = styled(Button)`
  display: block;
  margin: 10px auto;
  background-color: #547dde;
  color: #fff;
`

const SubmitButton = styled(Button)`
  display: block;
  min-width: 180px;
  margin: 5px auto;
`

const EntryFormView = () => {
  const [status, setStatus] = useState(formStatus.loading)
  const [formData, setFormData] = useState(null)

  useEffect(() => {
    firebase.firestore().collection('config').doc('entries').get()
      .then(doc => {
        setStatus(doc.exists && doc.data().enabled ? formStatus.enabled : formStatus.disabled)
      })
      .catch(err => {
        console.error(err)
        setStatus(formStatus.disabled)
      })
  }, [])

  const onCaptchaResult = useCallback(result => {
    if(result) {
      addNewEntry(formData)
        .then(() => setStatus(formStatus.sended))
        .catch(err => {
          console.error(err)
          message.error('Wystąpił błąd podczas wysyłania formularza!')
          setStatus(formStatus.enabled)
        })
        .finally(() => setFormData(null))
    }else{
      message.error('Weryfikacja CAPTCHA zakończona błędem!')
      setFormData(null)
      setStatus(formStatus.enabled)
    }
  }, [formData])

  const onFinish = useCallback(data => {
    setStatus(formStatus.sending)
    setFormData(data)
  }, [])

  const onFailed = useCallback(() => {
    message.error('Formularz zawiera błędy!')
    setFormData(null)
  }, [])

  switch (status) {
    case formStatus.loading:
      return <Message>Ładowanie formularza...</Message>
    case formStatus.sended:
      return <Message>Zgłoszenie zostało wysłane do weryfikacji!<br />Dziękujemy za wzięcie udziału w turnieju 😊</Message>
    case formStatus.disabled:
      return <Message>Zapisy do turnieju zostały zakończone.</Message>
    case formStatus.enabled:
    case formStatus.sending:
      return(
        <FormContainer>
          <Title>Formularz zgłoszeniowy</Title>
          <SubTitle>III edycja turnieju Ham Cup (2 VS 2)</SubTitle>
          <SubInformation>
            W formularzu rejestracyjnym oprócz nicku gracza należy podać link do jego profilu w serwisie <a href='https://rocketleague.tracker.network/' target='_blank' rel='noreferrer'>Rocket League Tracker Network</a>. Drużyna może zarejestrować trzeciego gracza, który będzie pełnił rolę rezerwowego, ale jego obecność nie jest obowiązkowa. Zarówno nicki graczy, jak i nazwy drużyn nie mogą zawierać słów wulgarnych lub powszechnie uważanych za obraźliwe. Organizator zastrzega sobie prawo do możliwości odrzucenia wniosku, jeśli łamie on regulamin turnieju.
          </SubInformation>
          <TRNbutton
            icon={<UserOutlined />}
            size={'large'}
            onClick={() => window.open('https://rocketleague.tracker.network/', '_blank').focus()}
          >
            Znajdź swój profil TRN
          </TRNbutton>
          <Form
            name='entry'
            layout='vertical'
            onFinish={onFinish}
            onFinishFailed={onFailed}
          >
            {
              entryFormFields.map(field => (
                <Form.Item
                  key={field.name}
                  label={field.publicLabel}
                  name={field.name}
                  rules={field.rules}
                  {...field.formItemProps}
                >
                  {
                    React.cloneElement(field.fieldComponent, {
                    ...field.formItemProps,
                      disabled: status === formStatus.sending
                    })
                  }
                </Form.Item>
              ))
            }
            <SubInformation>
              Rejestracja zespołu w turnieju oznacza akceptację regulaminu dostępnego pod <a href='https://kycu.live/hamcup/regulamin' target='_blank' rel='noreferrer'>tym adresem</a> oraz zezwolenie na przetwarzanie wprowadzonych w formularzu danych przez system informatyczny Ham Cup, <a href='https://rocketleague.tracker.network/' target='_blank' rel='noreferrer'>serwis TRN</a> oraz <a href='https://challonge.com/' target='_blank' rel='noreferrer'>serwis Challonge</a>. Dowódca zespołu jest dodatkowo zobowiązany do dołączenia do <a href='https://discord.com/invite/0fQGr35ORrEWY0B3' target='_blank' rel='noreferrer'>serwera Discord</a>, który będzie służył do komunikacji międzyzespołowej i ustalania terminów meczy.
            </SubInformation>
            <SubmitButton
              type='primary'
              htmlType='submit'
              disabled={status === formStatus.sending}
            >
              Zarejestruj drużynę
            </SubmitButton>
          </Form>
          <FormCaptcha isOpen={formData != null} onResult={onCaptchaResult} />
        </FormContainer>
      )
    default:
      return <></>
  }
}

export default EntryFormView
