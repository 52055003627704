import React, {useCallback, useMemo} from 'react'
import PropTypes from 'prop-types'
import firebase from '../../firebase/firebase'
import { Button, Space, Popconfirm, message, Tooltip } from 'antd'
import { DeleteOutlined, EditOutlined } from '@ant-design/icons'
import ModalRecordEditor from './ModalRecordEditor'
import {useBoolean} from 'ahooks'
import { findAndConvertTimestampToMoment, findAndConvertMomentToTimestamp } from '../../utils/dateConverter'

const ActionsColumn = ({ idx, record, config, collectionPath, deleteItemFromTable, replaceRecordInTable }) => {
  const [editItemModalVisible, {
    setTrue: showEditItemModalVisible,
    setFalse: hideEditItemModalVisible,
  }] = useBoolean(false)

  const currentRecordData = useMemo(() => findAndConvertTimestampToMoment(record), [record])

  const onEditItemModalFinish = useCallback(data => {
    if(Object.hasOwnProperty.call(config, 'onEditItemFormSubmit')) {
      config.onEditItemFormSubmit(data, record.key, (dataFormatter) => {
        hideEditItemModalVisible()
        if(dataFormatter) data = dataFormatter(data)
        const newData = findAndConvertMomentToTimestamp({
          ...record,
          ...data,
        })
        replaceRecordInTable(idx, newData)
      })
    }
  }, [replaceRecordInTable, hideEditItemModalVisible, record, config, idx])

  const onDeleteItem = useCallback(() => {
    firebase.firestore()
      .collection(collectionPath)
      .doc(record.key)
      .delete()
      .then(() => {
        deleteItemFromTable(idx)
        return message.success('Pozycja usunięta')
      })
      .catch(() => message.error('Wystąpił błąd podczas usuwania'))
  }, [collectionPath, record, idx, deleteItemFromTable])

  return(
    <>
      <Space>
        {
          config.actions.custom
          &&
          config.actions.custom(idx, record, deleteItemFromTable, replaceRecordInTable)
            .map(component => React.cloneElement(component))
        }
        {
          config.actions.editItemButton
                    &&
                    <Tooltip placement={'bottomRight'} title={'Edytuj'}>
                      <Button size={'large'} icon={<EditOutlined />} onClick={showEditItemModalVisible} />
                    </Tooltip>
        }
        {
          config.actions.deleteItemButton
                    &&
                    <Popconfirm
                      title={'Czy chcesz usunąć ten rekord?'}
                      onConfirm={onDeleteItem}
                      okText={'Tak'}
                      cancelText={'Nie'}
                      icon={<DeleteOutlined />}
                    >
                      <Tooltip placement={'bottomRight'} title={'Usuń'}>
                        <Button size={'large'} icon={<DeleteOutlined />} danger />
                      </Tooltip>
                    </Popconfirm>
        }
      </Space>
      {
        editItemModalVisible
                &&
                <ModalRecordEditor
                  show={true}
                  editMode={true}
                  initialValues={currentRecordData}
                  onCancel={hideEditItemModalVisible}
                  onFinish={onEditItemModalFinish}
                  fields={config.formFields}
                />
      }
    </>
  )
}

ActionsColumn.propTypes = {
  collectionPath: PropTypes.string.isRequired,
  deleteItemFromTable: PropTypes.func.isRequired,
  idx: PropTypes.number.isRequired,
  record: PropTypes.object.isRequired,
  replaceRecordInTable: PropTypes.func.isRequired,
  config: PropTypes.object,
}

export default ActionsColumn
