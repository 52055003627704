import React, { useEffect, useState, useCallback } from 'react'
import styled from 'styled-components'
import {listenCollection} from '../../firebase/firestore/firestore'
import {timestampToDate, timestampToString} from '../../utils/dateConverter'
import getMonthName from '../../utils/monthName'
import useQuery from '../../hooks/useQuery'

const Header = styled.div`
  background-color: #68D472;
  color: #000;
  padding: 3px 6px;
  font-weight: bold;
`

const Item = styled.div`
  background-color: #b7b7b7;
  color: #000;
  padding: 3px 6px;
`

const ScheduleWidget = () => {
  const [data, setData] = useState(null)
  const query = useQuery()

  const docFilter = useCallback(ref => {
    const from = new Date()
    from.setHours(0, 0, 0)
    const to = new Date()
    to.setHours(23, 59, 59)
    switch (query.mode) {
      case 'full': return ref
      case 'today': return ref.where('time', '>=', from).where('time', '<=', to)
      default: return ref.where('time', '>=', from)
    }
  }, [query])

  useEffect(() => {
    return listenCollection('schedule', d => {
      d.sort((a, b) => (timestampToDate(a.time) - timestampToDate(b.time)))
      const output = []
      let lastDay, lastMonth
      d.forEach(match => {
        const matchDate = timestampToDate(match.time)
        const matchDay = matchDate.getDate()
        const matchMonth = matchDate.getMonth() + 1
        if(matchDay !== lastDay || matchMonth !== lastMonth) {
          lastDay = matchDay
          lastMonth = matchMonth
          output.push({ isDateHeader: true, day: matchDay, month: matchMonth })
        }
        output.push({ isDateHeader: false, ...match })
      })
      setData(output)
    }, docFilter)
  }, [docFilter])

  if(!data) return <i>Ładowanie widoku...</i>
  if(data.length === 0) return <i>Lista zawodników jest pusta</i>

  return(
    <>
      {
        data.map(item => (
          item.isDateHeader
          ?
            <Header key={`H-${item.day}-${item.month}`}>{`${item.day} ${getMonthName(item.month)}`}</Header>
            :
            <Item key={item.docId}>{`${timestampToString(item.time, false)} - ${item.team1} vs ${item.team2}`}</Item>
        ))
      }
    </>
  )
}

export default ScheduleWidget
