import React, {useState, useEffect, useCallback, useMemo} from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import firebase from '../../firebase/firebase'
import { Button, message, Table } from 'antd'
import TableHeader from './TableHeader'
import { DownCircleOutlined } from '@ant-design/icons'
import ActionColumn from './ActionColumn'
import {useBoolean} from 'ahooks'

const LoadMoreButton = styled(Button)`
  display: block;
  margin: 15px auto 0;
`

const FirestoreTableBridge = ({
  dataSourcePath, defaultSortMethod, tableConfig, columns, staticModifiers, itemsPerPage, hideActionColumn,
}) => {
  const [modifiers, setModifiers] = useState({
    filter: {},
    sort: {},
  })
  const [tableData, setTableData] = useState([])
  const [lastItemReference, setLastItemReference] = useState(null)
  const [reachEndOfList, setReachEndOfList] = useBoolean(false)
  const [loadingState, {
    setTrue: startLoading,
    setFalse: endLoading,
  }] = useBoolean(true)

  const deleteItemFromTable = useCallback(idx => {
    setTableData(table => {
      if(table.length > idx) {
        const newTable = [...table]
        newTable.splice(idx, 1)
        if(idx === table.length - 1) {
          if(newTable.length > 0) {
            setLastItemReference(newTable[newTable.length - 1].referenceDoc)
          }else{
            setLastItemReference(null)
          }
        }
        return newTable
      }
    })
  }, [])

  const replaceRecordInTable = useCallback((idx, recordData) => {
    setTableData(table => {
      if(table.length > idx) {
        const newTable = [...table]
        newTable[idx] = recordData
        if(idx === newTable.length - 1) setLastItemReference(newTable[idx].referenceDoc)
        return newTable
      }
    })
  }, [])

  const tableColumns = useMemo(() => (
    hideActionColumn
      ?
      [...columns]
      :
      [
        ...columns,
        {
          title: 'Akcje',
          key: 'actions',
          render: (_, record, idx) => (
            <ActionColumn
              idx={idx}
              record={record}
              config={tableConfig}
              collectionPath={dataSourcePath}
              deleteItemFromTable={deleteItemFromTable}
              replaceRecordInTable={replaceRecordInTable}
            />
          ),
        },
      ]
  ), [columns, tableConfig, dataSourcePath, deleteItemFromTable, replaceRecordInTable, hideActionColumn])

  const downloadData = useCallback((addToCurrentResults = false) => {
    startLoading()
    console.log(`Downloading data from ${dataSourcePath}...`)
    let ref = firebase.firestore().collection(dataSourcePath)

    if(staticModifiers) {
      ref = staticModifiers(ref)
    }

    const filters = Object.keys(modifiers.filter)
    if(filters.length > 0) {
      filters.forEach(filterKey => {
        const filterValue = modifiers.filter[filterKey]
        const filterConfig = tableConfig.filterOption.find(f => f.key === filterKey)
        console.log(`Filter: ${filterKey} ${filterConfig.arrayContainsMode ? 'array-contains' : '=='} ${filterValue}`)
        ref = ref.where(filterKey, filterConfig.arrayContainsMode ? 'array-contains' : '==', filterValue)
      })
    }

    if(Object.keys(modifiers.sort).length > 0 ) {
      console.log(`Order by: ${modifiers.sort.key} ${modifiers.sort.desc ? 'desc' : 'asc'}`)
      ref = ref.orderBy(modifiers.sort.key, modifiers.sort.desc ? 'desc' : 'asc')
      modifiers.sort.extraFields.forEach(extraField => {
        console.log(`Extra order by: ${extraField} ${modifiers.sort.desc ? 'desc' : 'asc'}`)
        ref = ref.orderBy(extraField, modifiers.sort.desc ? 'desc' : 'asc')
      })
    }else{
      if(defaultSortMethod && itemsPerPage > 0) {
        console.log(`Default order by: ${defaultSortMethod.key} ${defaultSortMethod.direction ? defaultSortMethod.direction : 'asc'}`)
        ref = ref.orderBy(defaultSortMethod.key, defaultSortMethod.direction ? defaultSortMethod.direction : 'asc')
      }
    }

    if(itemsPerPage > 0) {
      if(addToCurrentResults && lastItemReference) ref = ref.startAfter(lastItemReference)
      ref = ref.limit(itemsPerPage)
    }

    ref.get().then(collectionSnapshot => {
      const docs = []
      collectionSnapshot.forEach(doc => {
        docs.push({
          referenceDoc: doc,
          key: doc.id,
          ...doc.data(),
        })
      })
      if(docs.length > 0) {
        setLastItemReference(docs[docs.length - 1].referenceDoc)
      }else{
        setLastItemReference(null)
      }
      if(addToCurrentResults) {
        setReachEndOfList.toggle(docs.length < itemsPerPage)
        setTableData(current => {
          const mergedData = [...current, ...docs]
          console.log(`Data expanded! Downloaded: ${docs.length} Total count: ${mergedData.length}`)
          return mergedData
        })
      }else{
        if(itemsPerPage > 0) {
          setReachEndOfList.toggle(docs.length < itemsPerPage)
        }else{
          setReachEndOfList.setFalse()
        }
        console.log(`Data fetched! Count: ${docs.length}`)
        setTableData(docs)
      }
      endLoading()
    }).catch(err => {
      if (err.code === 'permission-denied') message.error('Nie posiadasz uprawnień do tego widoku')
      console.error('Firestore table - ' + dataSourcePath, err, true)
      endLoading()
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataSourcePath, modifiers, defaultSortMethod, itemsPerPage, lastItemReference, staticModifiers])

  useEffect(() => {
    downloadData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modifiers, staticModifiers])

  return(
    <>
      <TableHeader
        applyModifiers={setModifiers}
        reloadData={downloadData}
        {...tableConfig}
      />
      <Table columns={tableColumns}
        loading={loadingState}
        pagination={false}
        dataSource={tableData}
        scroll={{ x: true }}
      />
      {
        !loadingState && itemsPerPage && !reachEndOfList
                &&
                <LoadMoreButton type={'primary'} size={'large'} onClick={() => downloadData(true)}>
                  <DownCircleOutlined /> Pokaż więcej
                </LoadMoreButton>
      }
    </>
  )
}

FirestoreTableBridge.propTypes = {
  columns: PropTypes.array.isRequired,
  dataSourcePath: PropTypes.string.isRequired,
  defaultSortMethod: PropTypes.object,
  hideActionColumn: PropTypes.bool,
  itemsPerPage: PropTypes.oneOfType([PropTypes.number, PropTypes.bool]),
  staticModifiers: PropTypes.func,
  tableConfig: PropTypes.object,
}

FirestoreTableBridge.defaultProps = {
  hideActionColumn: false,
  tableConfig: {},
  itemsPerPage: 15,
}

export default FirestoreTableBridge
