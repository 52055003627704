import { addNewRecord, updateRecord, deleteRecord } from './firestore'

export const addNewEntry = data => addNewRecord('entries', data)

export const updateEntry = (id, data) => updateRecord('entries', id, data)

export const addNewTeam = data => addNewRecord('teams', data)

export const updateTeam = (id, data) => updateRecord('teams', id, data)

export const acceptEntry = (entryId, data) => {
  return addNewTeam({
    player1: data.player1,
    player2: data.player2,
    tracker1: data.tracker1,
    tracker2: data.tracker2,
    reservePlayer: data.reservePlayer,
    reserveTracker: data.reserveTracker,
    highestRank: data.highestRank,
    teamName: data.teamName,
    platform1: data.platform1,
    platform2: data.platform2,
    reservePlatform: data.reservePlatform,
  })
    .then(() => deleteRecord('entries', entryId))
}
