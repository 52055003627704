import React, { useState, useCallback } from 'react'
import {Button, Card, message} from 'antd'
import { getFullCollection } from '../../firebase/firestore/firestore'
import copy from 'copy-to-clipboard'
import BulkAddImage from '../../assets/challonge-bulk-add.jpg'

const cardStyle = {
  maxWidth: 800
}

const TeamListExport = () => {
  const [active, setActive] = useState(true)
  const onClick = useCallback(() => {
    setActive(false)
    setTimeout(() => {
      getFullCollection('teams')
        .then(docs => {
          copy(docs.map(d => d.teamName).join('\r\n'))
          message.info('Dane zostały skopiowane do schowka!')
        })
        .catch(err => {
          console.error(err)
          message.error('Wystąpił błąd podczas eksportowania danych!')
        })
        .finally(() => {
          setTimeout(() => setActive(true), 5000)
        })
    }, 500)
  }, [])

  return(
    <Card title='Lista drużyn' style={cardStyle}>
      <Button type={'primary'} onClick={onClick} disabled={!active}>Generuj listę drużyn</Button>
      <p>
        Przydatne do przygotowania losowania drużyn w grupach lub do automatycznego dodawania drużyn do systemu Challonge - wystarczy wkleić wygenerowaną listę do pola "Bulk Add":
      </p>
      <img src={BulkAddImage} alt={'challonge'} style={{ maxWidth: '100%' }} />
    </Card>
  )
}

const DashboardDataExport = () => {

  return(
    <>
      <TeamListExport />
    </>
  )
}

export default DashboardDataExport
