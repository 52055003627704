import React, {useCallback, useEffect, useState} from "react";
import {getEntryFormState, setEntryFormState} from "../../../firebase/firestore/config.firestore";
import {LockOutlined, UnlockOutlined} from "@ant-design/icons";
import {Button} from "antd";
import {BigText, FooterButtons, PanelBlock, SmallText, Title} from "./CommonElements";

export const EntryFormPanel = () => {
    const [isOpen, setIsOpen] = useState(-1)
    useEffect(() => {
        getEntryFormState().then(state => setIsOpen(state ? 1 : 0))
    }, [])
    const onClick = useCallback(() => {
        setEntryFormState(isOpen === 0)
        setIsOpen(-1)
        setTimeout(() => getEntryFormState().then(state => setIsOpen(state ? 1 : 0)), 500)
    }, [isOpen])
    if(isOpen < 0) {
        return(
            <PanelBlock>
                <Title>Formularz rejestracji</Title>
                <SmallText>Pobieranie danych...</SmallText>
            </PanelBlock>
        )
    }
    return(
        <PanelBlock>
            <Title>Formularz rejestracji</Title>
            <BigText color={isOpen === 0 ? '#be1414' : '#159f15'}>{isOpen === 0 ? <LockOutlined /> : <UnlockOutlined />}</BigText>
            <FooterButtons>
                <Button size={'small'} danger={isOpen === 1} type={isOpen === 0 && 'primary'} onClick={onClick}>
                    {isOpen === 0 ? 'Odblokuj formularz' : 'Zablokuj formularz'}
                </Button>
            </FooterButtons>
        </PanelBlock>
    )
}