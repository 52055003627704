import React, { useState, useEffect, useContext, createContext } from 'react'
import firebase from '../firebase/firebase'

export const TeamsContext = createContext(null)

export const useTeamsContext = () => useContext(TeamsContext)

export const TeamsProvider = ({ children }) => {
  const [list, setList] = useState([])

  useEffect(() => {
    return firebase
      .firestore()
      .collection('teams')
      .onSnapshot(snap => {
        console.log(`TeamsContext: Data fetched! Collection size: ${snap.size}`)
        setList(snap.docs.map(doc => ({ id: doc.id, ...doc.data() })))
      })
  }, [])

  return(
    <TeamsContext.Provider value={list}>
      {children}
    </TeamsContext.Provider>
  )
}
